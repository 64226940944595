import { GET_DETAIL_MARKET_INDEX_TICKER, GET_DETAIL_MARKET_INDEX_TICKER_ERROR, GET_DETAIL_MARKET_INDEX_TICKER_SUCCESS, GET_LIST_MARKET_INDEX_TICKER, GET_LIST_MARKET_INDEX_TICKER_ERROR, GET_LIST_MARKET_INDEX_TICKER_SUCCESS } from "../../../constants/ActionTypes"
import { IMarketIndexTickerWithRawData, MarketIndexStateDto } from "../stateDtos/MarketData/MarketIndexStateDto"

interface ActionProps {
    data: MarketIndexStateDto[]
    detailDataMk: IMarketIndexTickerWithRawData | null
    type: string
}
const INIT_STATE: MarketIndexStateDto = {
    marketIndexList: [],
}
const MarketIndexReducer = (
    state = INIT_STATE,
    action: ActionProps
) => {
    switch (action.type) {
        case GET_LIST_MARKET_INDEX_TICKER: {
            return {
                ...state,
                marketIndexList: [],
            }
        }
        case GET_LIST_MARKET_INDEX_TICKER_SUCCESS: {
            return {
                ...state,
                marketIndexList: action.data,
            }
        }
        case GET_LIST_MARKET_INDEX_TICKER_ERROR: {
            return {
                ...state,
                marketIndexList: [],
            }
        }
        case GET_DETAIL_MARKET_INDEX_TICKER: {
            return {
                ...state,
                detailDataMk: null,
            }
        }
        case GET_DETAIL_MARKET_INDEX_TICKER_SUCCESS: {
            return {
                ...state,
                detailDataMk: action.detailDataMk,
            }
        }
        case GET_DETAIL_MARKET_INDEX_TICKER_ERROR: {
            return {
                ...state,
                detailDataMk: null,
            }
        }
        default:
            return state
    }
}
export default MarketIndexReducer