import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Avatar, Popover } from 'antd'
import { userSignOut } from '../../appRedux/actions/Auth'
import { useHistory } from 'react-router-dom'
import ModalMyProfile from '../profile/ModalMyProfile'
import { getProfileUser } from '../../appRedux/actions'

const UserInfo = () => {
  const { SysUserRecord, avatar } = useSelector(
    (state: RootStateOrAny) => state.sysUser
  )
  const [visible, setVisible] = useState(false)
  const [VisibleModal, setVisibleModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const mimeType = 'image/png'
  useEffect(() => {
    dispatch(getProfileUser({ sub: localStorage.getItem('username') }))
  }, [dispatch])
  // useEffect(() => {
  //   if (SysUserRecord) dispatch(getAvatarUser({ value: SysUserRecord?.avatarMedium }))
  // }, [dispatch, SysUserRecord])
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          setVisibleModal(true), setVisible(false)
        }}
      >
        My Profile
      </li>
      <li
        onClick={() => {
          history.push('/main/profile/change-password'), setVisible(false)
        }}
      >
        Change Password
      </li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  )
  const HandleClickChange = (
    visible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setVisible(visible)
  }
  const onOpenModal = () => {
    setVisibleModal(true)
  }
  const onCloseModal = () => {
    setVisibleModal(false)
  }
  return (
    <>
      <Popover
        overlayClassName="gx-popover-horizantal"
        onOpenChange={HandleClickChange}
        visible={visible}
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
           src={
            avatar &&
            `data:${mimeType};base64,${Buffer.from(
              avatar.data
            ).toString('base64')}`
          }
          className="gx-avatar gx-pointer"
          alt=""
        />
      </Popover>
      <ModalMyProfile visibleModal={VisibleModal} onCloseModal={onCloseModal} />
    </>
  )
}

export default UserInfo
